<template lang="pug">
v-container
  //- Upload Book Dialog Box
  v-overlay(opacity=".9" color="#f3f3f3" v-if="books==''").pl-lg-10
    v-progress-circular.ml-lg-15(
      :size="70"
      :width="7"
      color="primary"
      indeterminate)
  v-dialog.pa-2(v-model="dialog", fullscreen hide-overlay transition="dialog-bottom-transition")
    v-card.overflow-x-hidden(outlined, flat)
      v-spacer
      v-card-title.headline.justify-center.mt-3 Add Book Details
      v-spacer
      v-divider
      v-form(ref="form")
        div.px-4
          v-text-field.mx-7(
            label="Book Title*",
            outlined,
            clearable,
            v-model.trim="title",
            :rules="rules"
          )

        v-row
          v-col.text-center(cols="4")
            v-btn.mb-n15(
              @click="imageUpload = !imageUpload; loader = 'loading8'",
              color="primary",
              dark,
              small,
              style="z-index: 2",
              :loading="loading8"
              ) Upload Cover 

            v-dialog(v-model="imageUpload", width="600" persistent)
              v-card.text-center.pa-5.mr-n5.text-center
                h4 Upload image
                input(
                  type="file",
                  @change="previewImage",
                  accept="image/*"
                ).mt-5
                div.font-weight-light.mt-2
                  div(v-if="imageData!=null").mb-5 Chosen Image Size: <strong> {{imageData.size/1000}} KB </strong>
                    v-icon(v-if="imageData.size > 81000" color="red").mb-1 mdi-alert
                    v-icon(v-else color="success").mb-1 mdi-check-circle
                  div File size should not be greater than <strong> 80 KB </strong> for the optimization of the website.
                  div If the file size is greater than 80 KB, try to compressed the image file here:
                  a(href="https://imagecompressor.com/" target="blank")
                    strong Image Compress
                div.mt-5
                  v-btn(
                    @click="imageUpload = false; loading8=false",
                    color="error",
                    small
                  ).mr-10 Cancel
                  v-btn(
                    @click="loading9 = true; onUpload();",
                    v-if="imageData != null",
                    color="success",
                    small,
                    :loading="loading9"
                  ) Upload
                  v-btn(disabled, small, v-else) Upload
            .text-center.mt-n3(v-if="uploadValue == 100")
              img(
                :src="picture",
                style="width: 70%; height: 450px; object-fit: cover"
              )
            v-card.mx-auto.text-center.pt-15.mt-n3(
              v-else,
              width="70%",
              height="450px",
              outlined
            )
              h6.font-weight-light.text-muted 125x180
              v-progress-circular#progress(
                :size="100",
                :width="15",
                :value="uploadValue",
                color="primary"
              ) 
                v-icon.text-muted(large) mdi-file-image
              p.font-weight-bold {{ uploadValue.toFixed() + '%' }}

          v-col.ml-n12(cols="8")
            //-  PDF NAME
            v-row
              v-col(cols="3")
                v-card-subtitle.font-weight-medium.mt-n1 PDF File Name*
              v-col(cols="9")
                v-text-field.ml-n8(
                  placeholder="PDF File name.pdf",
                  outlined,
                  v-model.trim="pdfUrl",
                  @keyup.enter="checkPdf()"
                  @focusout="checkPdf()"
                  :rules="[(v) => !!v || 'PDF File name is required.',v => /.+.pdf+/.test(v) || 'Must end with .pdf']",
                  hint="click Enter to check if the file name is correct and valid"
                )
            //- Author
            v-row
              v-col(cols="3")
                v-card-subtitle.font-weight-medium.mt-n1 Authors / Editors*
              v-col(cols="9")
                v-combobox.ml-n8(
                  multiple,
                  hide-selected,
                  small-chips,
                  placeholder="Authors / Editors",
                  outlined,
                  v-model.trim="author",
                  :rules="rules",

                )
            //- Publisher
            v-row
              v-col(cols="3")
                v-card-subtitle.mt-n1.font-weight-medium Publisher*
              v-col.mb-n1(cols="9")
                v-text-field.ml-n8(
                  placeholder="Publisher",
                  outlined,
                  type="text"
                  v-model.trim="publisher",
                  :rules="rules",
                )

              //- Year
            v-row
              v-col(cols="3")
                v-card-subtitle.font-weight-medium Year*
              v-col(cols="9")
                v-text-field.ml-n8(
                  v-model.trim="year",
                  type="number",
                  placeholder="Publication Year",
                  outlined,
                  min="1800",
                  :rules="[(v) => !!v || 'Publication Year is required.', (v) => (v > 1000 && v <= (new Date().getFullYear()) + 3) || 'Not a valid publication year.']"
                )
              //- Edition
            v-row
              v-col(cols="3")
                v-card-subtitle.font-weight-medium Edition
              v-col(cols="9")
                v-text-field.ml-n8(
                  v-model.trim="edition",
                  type="text",
                  placeholder="1st edition",
                  outlined,
                )
              //- Subject
            v-row
              v-col(cols="3")
                v-card-subtitle.mt-1.font-weight-medium Subject
              v-col(cols="9")
                v-select.ml-n8(
                  :items="subjects",
                  placeholder="Subject",
                  v-model.trim="subject",
                  outlined,
                  multiple,
                  small-chips
                )
              //- Institution
            v-row
              v-col(cols="3")
                v-card-subtitle.mt-n2.font-weight-medium Institution
              v-col(cols="9")
                v-select.ml-n8(
                  placeholder="Institution",
                  :items="institutions",
                  outlined,
                  v-model="institution",
                  multiple,
                  small-chips
                )
            //- ISBN  
            v-row 
              v-col(cols="3")
                v-card-subtitle.font-weight-medium.mt-n1 ISBN
              v-col(cols="9")
                v-text-field.ml-n8(
                  outlined,
                  placeholder="ISBN-XXX-XXXXX-XXXXXXX-XXXXXX-X",
                  v-model.trim="isbn"
                ) 
            //- DOI 
            v-row 
              v-col(cols="3")
                v-card-subtitle.font-weight-medium.mt-n1 DOI
              v-col(cols="9")
                v-text-field.ml-n8(
                  outlined,
                  placeholder="10.XXXX/XXXX",
                  v-model.trim="doi"
                ) 

        v-card-actions.mb-7
          v-spacer
          v-btn.mr-2(
            dark,
            color="rgba(163, 0, 3, 0.8)",
            width="120px",
            @click="dialog = false; loader = 'loading16'; loading5 = false;",
            :loading="loading16"
          ) Close

          v-btn.mr-16.white--text(
            :disabled="uploadValue != 100",
            color="rgba(2, 0, 115, 0.8)",
            width="120px",
            @click="loading = true; createBook(); loading5 = false;",
            :loading="loading",
          ) Upload

  FlashMessage(
    :position="'left bottom'",
    :strategy="'multiple'",
    style="z-index: 99"
  )

  //- BOOKS
  ais-instant-search(
    :search-client="searchClient",
    index-name="books",
  )
    ais-configure(
      :query="search",
      :hits-per-page.camel="5"
      v-if="search.length >= 3 || algolia == true"
    )
    v-row
      v-col(md="10", cols="12")
        ais-pagination
          div(slot-scope="{refine}")
            v-text-field(
              placeholder="Search",
              v-model="search",
              solo,
              dense,
              @focus="refine(0)"
              prepend-inner-icon="mdi-magnify",
              clearable
            )
      v-col.d-flex.justify-content-end(md="2", cols="12")
        v-btn(
          color="green",
          @click="dialog = !dialog; loader = 'loading5'; loading16 = false",
          dark,
          :loading="loading5"
          ref="fileInput"
        ) 
          v-icon.mr-2 mdi-upload
          span Upload Book
    v-card.px-5.white--text.mb-2.text-center.pl-6.hidden-sm-and-down(
      color="rgba(2, 0, 115, 0.8)"
    )
      v-row
        v-col.text-left.d-flex.align-items-center.justify-content-center(
          md="2",
          cols="12"
        )
          h6 Cover
        v-col.text-left.d-flex.align-items-center.justify-content-center(
          md="4",
          cols="12"
        )
          h6 Book Details 
        v-col.d-flex.align-items-center.justify-content-center(md="1", cols="6")
          h6 Views
        v-col.d-flex.align-items-center.d-flex.justify-content-center(
          md="2",
          cols="6"
        )
          h6 Date Modified
        v-col.d-flex.align-items-center.justify-content-center(md="1", cols="4")
          h6 PDF File
        v-col.d-flex.align-items-center.justify-content-center(md="1", cols="4")
          h6 Update <br> Details
        v-col.d-flex.align-items-center.justify-content-center(md="1", cols="4")
          h6 Remove <br> Book
    div(v-if="search.length >= 3 || algolia == true")
      ais-hits(ref="aisHit")
        div(slot-scope="{ items }") 
          div(v-for="book in items", :key="book.objectID" v-if="items.length>0")
            v-card.pa-5(tile, outlined)
              v-row
                v-col.d-flex.justify-content-center(md="2", cols="12")
                  img(
                    :src="book.imageUrl",
                    style="width: 80%; height: 180px; object-fit: cover"
                  )
                v-col(md="4", cols="12")
                  h6.font-weight-regular.subtitle-1 <strong>Title: {{ book.title }}</strong>
                  h6.font-weight-regular Author/s: <span v-for="author in book.author.slice(0,book.author.length-1)">{{ author }}, </span>
                    <span> {{ book.author[book.author.length-1] }} </span>
                  h6.font-weight-regular Publisher: {{ book.publisher }}
                  h6.font-weight-regular Year: {{ book.year }}
                  h6.font-weight-regular(v-if="book.edition.length != 0") Edition: {{ book.edition }}
                  h6.font-weight-regular(v-if="book.subject.length !=0 && book.subject!=null") Subjects: <span v-for="subject in book.subject.slice(0,book.subject.length-1)">{{ subject }}, </span><span> {{ book.subject[book.subject.length-1] }} </span>
                  h6.font-weight-regular(v-if="book.institution.length !=0 && book.institution!=null") Institutions: <span v-for="institution in book.institution.slice(0,book.institution.length-1)">{{ institution }}, </span><span> {{ book.institution[book.institution.length-1] }} </span>
                  h6.font-weight-regular(v-if="book.isbn != ''") ISBN: {{ book.isbn }}
                  h6.font-weight-regular(v-if="book.doi != ''") DOI: {{ book.doi }} 
                  h6.font-weight-regular(v-if="book.addedBy") Added by: {{book.addedBy}}
                  h6.font-weight-regular(v-if="book.modifiedBy") Modified by: {{book.modifiedBy}}
                v-col.text-center(md="1", cols="5")
                  h6.font-weight-regular <span v-if="$vuetify.breakpoint.smAndDown">Views:</span> {{ book.views }}
                v-col.d-flex.justify-content-center(md="2", cols="7")
                  h6.font-weight-regular <span v-if="$vuetify.breakpoint.smAndDown">Added:</span> {{ (new Date(book.date)).toString().split('GMT')[0] }}
                v-col(md="1", cols="4")
                  v-row.d-flex.justify-content-center
                    a(:href="'/book/' + book.objectID" target="_blank")
                      v-btn(
                        color="teal",
                        dark,
                        fab,
                        small,
                        elevation="0",
                      )
                        v-icon mdi-eye
                  v-row.d-flex.justify-content-center
                    .body-2.d-block View
                v-col(md="1", cols="4")
                  v-row.d-flex.justify-content-center
                    router-link(:to="'../admin/edit-book/' + book.objectID")
                      v-btn.d-block(
                        small,
                        fab,
                        elevation="0",
                        dark,
                        color="indigo",
                        :loading="loading1 && book.objectID == index",
                        @click="loader = 'loading1'; index = book.objectID"
                      )
                        v-icon mdi-pencil
                  v-row.d-flex.justify-content-center
                    .body-2.d-block Edit
                v-col(md="1", cols="4")
                  v-row.d-flex.justify-content-center
                    v-btn(
                      color="error",
                      fab,
                      small,
                      elevation="0",
                      :loading="loading2 && book.objectID == index",
                      @click="deleteBox(book.id, book.title, book.imageUrl); loader = 'loading2'; index = book.objectID"
                    ) 
                      v-icon mdi-delete
                  v-row.d-flex.justify-content-center
                    .body-2.d-block Delete

          div( v-if="items.length==0") NO RESULTS FOUND :(
      ais-pagination
        div(
          slot-scope="{currentRefinement, nbPages,pages,isFirstPage,isLastPage,refine,createURL}"
        )
          .text-center.mt-5(v-if="pages.length >= 2")
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-if="!isFirstPage",
              @click.prevent="refine(0); algolia = false",
              outlined
            )
              v-icon mdi-chevron-double-left
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-if="isFirstPage"
              @click.prevent="algolia = false",
              outlined
            )
              v-icon mdi-menu-left
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-else-if="currentRefinement == 1"
              @click.prevent="refine(0); algolia = false",
              outlined
            )
              v-icon mdi-menu-left
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-else
              @click.prevent="refine(currentRefinement - 1)",
              outlined
            )
              v-icon mdi-menu-left
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              :outlined="0 != currentRefinement",
              @click.prevent="refine(0);algolia = false"
            ) {{ pages[0] + 1 }}
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-for="page in pages.slice(1,6)",
              :key="page",
              :outlined="page != currentRefinement",
              @click.prevent="refine(page)"
            ) {{ page + 1 }}
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-if="!isLastPage",
              @click.prevent="refine(currentRefinement + 1)",
              outlined
            )
              v-icon mdi-menu-right
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-if="!isLastPage",
              @click.prevent="refine(nbPages)",
              outlined
            )
              v-icon mdi-chevron-double-right
    div(v-else)
      div(v-for="book in books", :key="book.id")
        v-card.pa-5(tile, outlined)
          v-row
            v-col.d-flex.justify-content-center(md="2", cols="12")
              img(
                :src="book.imageUrl",
                style="width: 80%; height: 180px; object-fit: cover"
              )
            v-col(md="4", cols="12")
              h6.font-weight-regular.subtitle-1 <strong>Title: {{ book.title }}</strong>
              h6.font-weight-regular Author/s: <span v-for="author in book.author.slice(0,book.author.length-1)">{{ author }}, </span>
                <span> {{ book.author[book.author.length-1] }} </span>
              h6.font-weight-regular Publisher: {{ book.publisher }}
              h6.font-weight-regular Year: {{ book.year }}
              h6.font-weight-regular(v-if="book.edition.length != 0") Edition: {{ book.edition }}
              h6.font-weight-regular(v-if="book.subject.length !=0 && book.subject!=null") Subjects: <span v-for="subject in book.subject.slice(0,book.subject.length-1)">{{ subject }}, </span><span> {{ book.subject[book.subject.length-1] }} </span>
              h6.font-weight-regular(v-if="book.institution.length !=0 && book.institution!=null") Institutions: <span v-for="institution in book.institution.slice(0,book.institution.length-1)">{{ institution }}, </span><span> {{ book.institution[book.institution.length-1] }} </span>
              h6.font-weight-regular(v-if="book.isbn != ''") ISBN: {{ book.isbn }}
              h6.font-weight-regular(v-if="book.doi != ''") DOI: {{ book.doi }} 
              h6.font-weight-regular(v-if="book.addedBy") Added by: {{book.addedBy}}
              h6.font-weight-regular(v-if="book.modifiedBy") Modified by: {{book.modifiedBy}}
            v-col.text-center(md="1", cols="5")
              h6.font-weight-regular <span v-if="$vuetify.breakpoint.smAndDown">Views:</span> {{ book.views }}
            v-col.d-flex.justify-content-center(md="2", cols="7")
              h6.font-weight-regular <span v-if="$vuetify.breakpoint.smAndDown">Added:</span> {{ (new Date(book.date)).toString().split('GMT')[0] }}
            v-col(md="1", cols="4")
              v-row.d-flex.justify-content-center
                a(:href="'/book/' + book.id" target="_blank")
                  v-btn(
                    color="teal",
                    dark,
                    fab,
                    small,
                    elevation="0",
                  )
                    v-icon mdi-eye
              v-row.d-flex.justify-content-center
                .body-2.d-block View
            v-col(md="1", cols="4")
              v-row.d-flex.justify-content-center
                router-link(:to="'../admin/edit-book/' + book.id")
                  v-btn.d-block(
                    small,
                    fab,
                    elevation="0",
                    dark,
                    color="indigo",
                    :loading="loading1 && book.id == index",
                    @click="loader = 'loading1'; index = book.id"
                  )
                    v-icon mdi-pencil
              v-row.d-flex.justify-content-center
                .body-2.d-block Edit
            v-col(md="1", cols="4")
              v-row.d-flex.justify-content-center
                v-btn(
                  color="error",
                  fab,
                  small,
                  elevation="0",
                  :loading="loading2 && book.id == index",
                  @click="deleteBox(book.id, book.title, book.imageUrl); loader = 'loading2'; index = book.id"
                ) 
                  v-icon mdi-delete
              v-row.d-flex.justify-content-center
                .body-2.d-block Delete
      ais-pagination
        div(
          slot-scope="{currentRefinement, nbPages,pages,isFirstPage,isLastPage,refine,createURL}"
        )
          .text-center.mt-5(v-if="pages.length >= 2")
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              outlined
            )
              v-icon mdi-menu-left
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              :outlined="0 != currentRefinement",
            ) {{ pages[0] + 1 }}
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-for="page in pages.slice(1,6)",
              :key="page",
              :outlined="page != currentRefinement",
              @click.prevent="refine(page); algolia = true"
            ) {{ page + 1 }}
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-if="!isLastPage",
              @click.prevent="refine(currentRefinement + 1); algolia = true",
              outlined
            )
              v-icon mdi-menu-right
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-if="!isLastPage",
              @click.prevent="refine(nbPages); algolia = true",
              outlined
            )
              v-icon mdi-chevron-double-right

  v-dialog(v-model="deleteDialog", width="600")
    v-card
      v-card-title.headline.grey.lighten-2 Delete Book
      .pa-5
        p.font-weight-light Are you sure do you want to delete the book titled <strong>{{ bookTitle }}</strong>?
      v-divider
      v-card-actions.px-5.pb-5
        v-spacer
        v-btn(color="grey", text, @click="deleteDialog = false; loading2=false") Cancel
        v-btn(color="error", @click="deleteBook(bookId); loader = 'loading12'"
        :loading="loading12"
          ) Delete
  v-dialog(v-model="duplicateWarn", width="600")
    v-card
      v-card-title.headline.grey.lighten-2 Duplicate Book
      div.pa-5
        div(v-for="book in duplicate" :key="book.id")
          p.font-weight-light Sorry. You cannot add this book. This is already added last {{ (new Date(book.date)).toString().split('GMT')[0] }} with the title <strong>{{ book.title }}</strong>.
          div.mt-n3.font-weight-light.body-2 Book Info:
          div.font-weight-light.body-2 Author/s: <span v-for="author in book.author.slice(0,book.author.length-1)">{{ author }}, </span>
            <span> {{ book.author[book.author.length-1] }} </span>
          div.font-weight-light.body-2 Publisher: {{ book.publisher }}
          div.font-weight-light.body-2 Year: {{ book.year }}
          div.font-weight-light.body-2(v-if="book.edition.length != 0") Edition: {{ book.edition }}
          div.font-weight-light.body-2(v-if="book.subject.length !=0 && book.subject!=null") Subjects: <span v-for="subject in book.subject.slice(0,book.subject.length-1)">{{ subject }}, </span><span> {{ book.subject[book.subject.length-1] }} </span>
          div.font-weight-light.body-2(v-if="book.institution.length !=0 && book.institution!=null") Institutions: <span v-for="institution in book.institution.slice(0,book.institution.length-1)">{{ institution }}, </span><span> {{ book.institution[book.institution.length-1] }} </span>
          div.font-weight-light.body-2(v-if="book.isbn != ''") ISBN: {{ book.isbn }}
          div.font-weight-light.body-2(v-if="book.doi != ''") DOI: {{ book.doi }} 
          div.font-weight-light.body-2(v-if="book.addedBy") Added by: {{book.addedBy}}
          div.font-weight-regular.mt-3.font-weight-light If the info about the book above is incorrect or inaccurate, you can modify it by clicking this button:
            a.ml-3(:href="'../admin/edit-book/' + book.id") 
              v-btn(small dark color="indigo") Edit Book
                v-icon.ml-1(small) mdi-pencil
      v-divider
      v-card-actions.px-5.pb-5
        v-spacer
        v-btn(color="grey" dark, @click="duplicateWarn=false").mr-2 Close
</template>

<script>
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import firebase from "firebase/app";
import "firebase/functions";
import { db } from "../main";
let deleteBook = firebase.functions().httpsCallable("deleteBook");
let createBook = firebase.functions().httpsCallable("createBook");
let incrementTotals = firebase.functions().httpsCallable("incrementTotals");
let decrementTotals = firebase.functions().httpsCallable("decrementTotals");

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: "Y1ojbcNINEhgaajOS6V6XQZAlKmDXCcE", // Be sure to use an API key that only allows search operations
    nodes: [
      {
        host: "ehubstorage.cslib.upd.edu.ph",
        port: "443",
        protocol: "https",
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  additionalSearchParameters: {
    queryBy: "title, author, publisher, isbn",
    sortBy: "_text_match:desc, date:desc",
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

export default {
  data() {
    return {
      searchClient,
      search: "",
      books: [],
      algolia: false,
      author: "",
      isbn: "",
      doi: "",
      edition: "",
      pdfUrl: "",
      title: "",
      bookId: "",
      bookTitle: "",
      bookImageUrl: "",
      year: null,
      subject: [],
      institution: [],
      publisher: "",
      dialog: false,
      imageUpload: false,
      modal: false,
      deleteDialog: false,
      subjects: [
        "Biology",
        "Chemistry",
        "Environmental Science",
        "Forensic Science",
        "General Science",
        "Geological Science",
        "Marine Science",
        "Material Science",
        "Mathematics",
        "Molecular Biology & Biotechnology",
        "Physics",
      ],
      institutions: [
        "Institute of Biology",
        "Institute of Chemistry",
        "Institute of Environmental Science and Meteorology",
        "Institute of Mathematics",
        "Marine Science Institute",
        "Materials Science and Engineering Program",
        "National Institute of Geological Sciences",
        "National Institute of Molecular Biology and Biotechnology",
        "National Institute of Physics",
      ],
      activator: null,
      attach: null,
      loader: null,
      loading: false,
      loading1: false,
      loading2: false,
      loading5: false,
      loading8: false,
      loading9: false,
      loading12: false,
      loading16: false,
      rules: [(v) => v.length != 0 || "Required."],
      imageData: null,
      picture: null,
      uploadValue: 0,
      email: "",
      duplicate: [],
      duplicateWarn: false,
    };
  },

  async created() {
    this.flashMessage.setStrategy("multiple");
    await this.$bind(
      "books",
      db
        .collection("books")
        .orderBy("date", "desc")
        .limit(5)
    );
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(() => {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(async (tokenResult) => {
            this.email = tokenResult.claims.email;
          });
      });
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      // setTimeout(() => (this[l] = false), 5000);
      this.loader = null;
    },
  },

  methods: {
    async deleteBook(id) {
      let title = this.bookTitle;
      await deleteBook({
        id,
      });
      await decrementTotals({
        type: "books",
      });
      let imageRef = firebase.storage().refFromURL(this.bookImageUrl);
      imageRef.delete();
      this.deleteDialog = false;
      this.loading12 = false;
      this.flashMessage.error({
        title: "Book was deleted successfully",
        message: "You successfully deleted the book " + title + "!",
        icon:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg1IO_-pku4M8rgrRdWC4CFAyK_jH_QLcOGA&usqp=CAU",
      });
    },

    async deleteBox(id, title, imageUrl) {
      this.bookId = id;
      this.bookTitle = title;
      this.bookImageUrl = imageUrl;
      this.deleteDialog = true;
      this.loading2 = false;
    },

    async createBook() {
      this.$bind(
        "duplicate",
        db
          .collection("books")
          .where("pdfUrl", "==", this.pdfUrl)
          .limit(2)
      ).then(async () => {
        if (this.duplicate.length >= 1 && this.$refs.form.validate()) {
          this.duplicateWarn = true;
          this.loading = false;
        } else if (this.$refs.form.validate()) {
          await createBook({
            author: this.author,
            title: this.title,
            edition: this.edition,
            publisher: this.publisher,
            institution: this.institution,
            subject: this.subject,
            year: Number(this.year),
            imageUrl: this.picture,
            pdfUrl: this.pdfUrl,
            date: Date.now(),
            isbn: this.isbn,
            doi: this.doi,
            views: 0,
            addedBy: this.email,
          });
          await incrementTotals({
            type: "books",
          });
          this.$bind(
            "books",
            db
              .collection("books")
              .orderBy("date", "desc")
              .limit(5)
          );
          this.loading = false;
          this.flashMessage.success({
            title: "Book was added successfully",
            message: "You successfully added the book " + this.title + "!",
            icon:
              "https://www.kindpng.com/picc/m/225-2258183_transparent-solution-icon-png-success-icon-transparent-png.png",
          });
          this.dialog = false;
          this.author = "";
          this.title = "";
          this.edition = "";
          this.publisher = "";
          this.institution = [];
          this.subject = [];
          this.year = null;
          this.picture = null;
          this.pdfUrl = "";
          this.imageData = null;
          this.uploadValue = 0;
          this.isbn = "";
          this.doi = "";
        } else {
          this.loading = false;
          this.$store.dispatch("setSnackbar", {
            text: "  Please fill the required fields!",
            icon: "mdi-block-helper",
            iconColor: "error",
          });
        }
      });
    },
    checkPdf() {
      var url = `https://ehubstorage.cslib.upd.edu.ph/home/pdf/${this.pdfUrl}`;
      try {
        var http = new XMLHttpRequest();
        http.open("HEAD", url, false);
        http.send();
        if (http.status != 404) {
          alert("File name is correct and valid.");
        } else {
          alert("not valid");
        }
      } catch (err) {
        alert("The file name is either incorrect or does not exist.");
      }
    },

    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },
    generateString(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    onUpload() {
      if ((this, this.imageData.size > 80000)) {
        this.imageData = null;
        this.$store.dispatch("setSnackbar", {
          text: "  Please upload image file size lower than 8O KB",
          icon: "mdi-block-helper",
          iconColor: "error",
        });
        this.loading9 = false;
        this.loading8 = false;
      } else {
        this.picture = null;
        //create reference to storage and storage file
        const storageRef = firebase
          .storage()
          .ref(`images/${this.generateString(30)}${this.imageData.name}`) //ref is directory of file in console
          .put(this.imageData);
        this.imageUpload = false;
        //progress bar
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.log(error.message);
          },
          () => {
            this.uploadValue = 100;
            //downloading photo url for front-end display
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              //put upload to database code

              this.picture = url;
              this.$store.dispatch("setSnackbar", {
                text: "  Finished uploading the book cover.",
                icon: "mdi-check",
                iconColor: "success",
              });
            });
            this.loading9 = false;
            this.loading8 = false;
          }
        );
      }
    },
  },
};
</script>

<style>
.v-card {
  z-index: 0;
}

.base-image-input {
  display: block;
  margin-left: 9%;
  width: 370px;
  height: 540px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

._vue-flash-msg-body {
  z-index: 99 !important;
}

.vue-flash-msg-body__content {
  padding: 5px !important;
  z-index: 99 !important;
}

._vue-flash-msg-body__title {
  font-weight: bold;
  margin-top: 9px;
  font-size: 20px;
  padding-right: 10px;
  font-family: sans-serif;
  z-index: 99 !important;
}

._vue-flash-msg-body__text {
  margin-top: -15px;
  font-family: sans-serif;
  padding-right: 10px;
  z-index: 99 !important;
}

/* _vue-flash-msg-body _vue-flash-msg-_left-top */

/* _vue-flash-msg-body__content
class="_vue-flash-msg-body__title"
class="_vue-flash-msg-body__text" */

/* success: https://www.kindpng.com/picc/m/225-2258183_transparent-solution-icon-png-success-icon-transparent-png.png */
</style>
